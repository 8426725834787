<script>
import BVForm from "@/components/BVForm/BVForm.vue";
import Swal from "sweetalert2";

export default {
    components: {
        BVForm
    },
    name: "NuevaVacante",
    data() {
        return {
            nuevaVacante: null,
            cargos: [],
            cargo: null
        }
    },
    async mounted() {
        this.nuevaVacante = JSON.parse(JSON.stringify(await this.$store.dispatch('getNuevaVacante', this.empresaId)))
        this.cargos = await this.$store.dispatch('getCargosByEmpresa', this.empresaId)
        console.log(this.cargos);
    },
    methods: {
        watcherForm({ name, value, idx }) {
            if (name == 'paisId') {
                this.nuevaVacante[idx + 1].value = null
                this.nuevaVacante[idx + 2].value = null
                this.nuevaVacante[idx + 1].options = this.getDepartamentosByPaisId(value)
            }
            else if (name == 'departamentoId') {
                this.nuevaVacante[idx + 1].value = null
                this.nuevaVacante[idx + 1].options = this.getMunicipiosByDepartamentoId(value)
            }
            else if (name == 'sucursalId') {
                this.nuevaVacante[idx + 1].value = null
                this.nuevaVacante[idx + 2].value = null
                this.nuevaVacante[idx + 1].options = this.getDepartamentosEmpresaBySucursalid(value)
            }
            else if (name == 'departamentoIdEmpresa') {
                this.nuevaVacante[idx + 1].value = null
                this.nuevaVacante[idx + 1].options = this.getAreasByDepartamentoId(value)
            }


        },
        getDepartamentosByPaisId(paisId) {
            return this.$store.getters.getSourceData('departamentos').filter(item => item.paisId == paisId)
        },
        getMunicipiosByDepartamentoId(departamentoId) {
            return this.$store.getters.getSourceData('municipios').filter(item => item.departamentoPaisId == departamentoId)
        },
        getDepartamentosEmpresaBySucursalid( sucursalId )
        {
            return this.$store.getters.getSourceData('departamentosByEmpresa').filter( item => item.sucursal.id == sucursalId )
        },
        getAreasByDepartamentoId( departamentoId )
        {
            return this.$store.getters.getSourceData('areasByEmpresa').filter( item => item.departamento.id == departamentoId )
        },

        openModal()
        {
            this.$bvModal.show('modal-relacionar-cargo-vacante')
        },
        async relacionarCargo( self )
        {
            this.$bvModal.hide('modal-relacionar-cargo-vacante')
            
            const response = await this.$store.getters.fetchGet({ path: 'Cargo/all/' + self.data.cargoId })
            const cargo = await response.json()
            this.cargo = cargo

            this.nuevaVacante[0].value = cargo.nombre
            this.nuevaVacante[0].state = 'disabled'
            this.nuevaVacante[1].value = cargo.tipoCargoId
            this.nuevaVacante[1].state = 'disabled'
            this.nuevaVacante[2].value = cargo.cargoDependienciaId
            this.nuevaVacante[2].state = 'disabled'
            this.nuevaVacante[3].value = cargo.sucursalId
            this.nuevaVacante[3].state = 'disabled'
            this.nuevaVacante[4].value = cargo.departamentoId
            this.nuevaVacante[4].state = 'disabled'
            this.nuevaVacante[5].value = cargo.areaId
            this.nuevaVacante[5].state = 'disabled'

            this.nuevaVacante = [...this.nuevaVacante]
        },
        cancelarRelacion() {
            this.cargo = null
            for( let i = 0; i < 6; i++ ) {
                this.nuevaVacante[i].value = null
                this.nuevaVacante[i].state = ''
            }
        },
        async crearVacante( self )
        {
            self.loader = true
            
            if( this.cargo == null ) {
                const nuevoCargo = {
                    nombre: self.data.nombre,
                    tipoCargoId: self.data.tipoCargoId,
                    cargoDependienciaId: self.data.departamentoIdEmpresa,
                    sucursalId: self.data.sucursalId,
                    areaId: self.data.areaId,
                }

                const response = await this.$store.getters.fetchPost({ 
                    path: 'Cargo/NuevoCargo', 
                    data: nuevoCargo 
                })

                this.cargo = await response.json()  
                
            }
            const vacante = {
                cargoId: this.cargo.id,
                fechaDeCierre: self.data.fechaDeCierre,
                salario: self.data.salario,
                tipoDeContratoId: self.data.tipoDeContratoId,
                cantidadDeVacantesInicial: self.data.cantidadDeVacantesInicial,
                paisId: self.data.paisId,
                departamentoId: self.data.departamentoId,
                municipioId: self.data.municipioId,
                direccion: self.data.direccion,
                jornadaLaboralId: self.data.jornadaLaboralId,
                descripcion: self.data.descripcion,
            }

            console.log(vacante);

            this.$store.getters.fetchPost({ path: 'vacante', data: vacante })
            .then( response => {
                if( response.ok )
                {
                    return response.json()
                }

                throw Error('Algo salió mal!')
            })
            .then( () => {
                // Swal tiene la opción de crear una nueva vacante que refresca la página o 
                Swal.fire({
                    icon: 'success',
                    title: 'Vacante creada con éxito!',
                    text: 'La vacante se ha creado con éxito!',
                    showCancelButton: true,
                    cancelButtonText: 'Ir al cargo',
                    confirmButtonText: 'Crear otra vacante'
                })
                .then( result => {
                    if( result.isConfirmed ) {
                        this.$router.go()
                    } else {
                        this.$router.push({ name: 'Cargos_editar', params: { id: this.cargo.id } })
                    }
                })
            })
            .catch( error => {
                Swal.fire({
                    icon: 'error',
                    title: error,
                    text: 'Algo salió mal!',
                })
            })
            .finally( () => {
                self.loader = false
            })
            
        }
    },
    computed: {
        empresaId() {
            return this.$store.getters.userLoggedIn.empresa.id
        }
    }
}
</script>

<template>
    <div class="container-fluid">
        <card class="px-3 my-2">
            <BVForm :schema="nuevaVacante" :callBackSubmit="crearVacante"
            :observer="['paisId', 'departamentoId', 'sucursalId', 'departamentoIdEmpresa']" @watcher="watcherForm">
                <template #header>
                    <div class="d-flex justify-content-between mb-3 pt-2">
                        <h3>Nueva vacante</h3>
                        <div>
                            <div v-if="cargo == null"
                            @click="openModal" class="btn btn-primary">
                                Relacionar con cargo existente
                            </div>
                            <div v-else
                            @click="cancelarRelacion" class="btn btn-outline-danger">
                                <i class="fas fa-times"></i>
                                Cancelar relación
                            </div>
                        </div>
                    </div>
                </template>

                <template #text-submit>
                    Crear vacante
                </template>
            </BVForm>

        </card>

        <b-modal id="modal-relacionar-cargo-vacante" class="modal-sq" size="lg" hide-footer hide-header>
            <template #default="{ close }">
                <div class="px-3 py-2">
                    <div class="d-flex justify-content-between mb-3" style="width: 100%;">
                        <p class="mb-0 h4"><strong></strong></p>
                        <div @click="close()" class="btn btn-danger btn-sm btn-pm">
                            <i class="fas fa-times"></i>
                        </div>
                    </div>
                    <div>
                        <BVForm
                            title="Seleccione el cargo que desea relacionar con la vacante"
                            :schema="[{
                                name: 'cargoId',
                                type: 'select',
                                label: 'Cargos',
                                options: cargos
                            }]"
                            :callBackSubmit="relacionarCargo"
                        >
                        </BVForm>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>


<style scoped>

.btn-pm {
    position: relative;
    min-width: 20px;
    height: 20px;
    text-align: center;
    padding: 2px 5px;
    margin: 0px 2px;
    border: none;
    border-radius: 5px;
}

.btn-pm i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

</style>